<template>
  <div id="app">
    <el-container>
      <!-- <el-header>
        <headerview></headerview>
      </el-header> -->
      <el-main>
        <router-view></router-view>
      </el-main>
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </div>
</template>

<script>
import headerview from "./components/headerview.vue";
export default {
  data() {
    return {};
  },
  components: {
    headerview
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
import MyHeader from "@/components/Header.vue";
import MyFooter from "@/components/Footer.vue";

export default {
  // name: "首页",
  data() {
    return {
     
    };
  },
  components:{
    MyHeader,
    MyFooter,
  },
  methods:{
    
  },
};
</script>


<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
.el-main{
  padding: 0 !important;
  margin: 0;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
/* 全部默认样式清空 */
  * {
    margin: 0;
    padding: 0;
    // box-sizing: border-box;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex{
    display: flex;
  }
  .flex-space-around{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .flex-space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-space-evenly{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
</style>
