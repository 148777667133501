<template>
<div>
    <div class="top-wapper">
      <div class="inner">
        <div class="titletext">核心 人才队伍</div>
        <div class="type">从小型散户投资者到大型机构</div>
        <div class="solution">解决方案</div>
      </div>
    </div>
    <div class="content-wapper">
      <div class="inner">
        <div class="title">
          <div class="img"></div>
          <div class="t">优秀的团队资源</div>
          <div class="d">Company Introduction</div>
          <div class="dec">—</div>
        </div>
        <div class="teamlist">
          <el-row>
            <el-col :span="8" v-for="i in list" :key="i.id">
              <div class="item">
                <img :src="i.path" alt />
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="title t2">
          <div class="img"></div>
          <div class="t">个人优势</div>
          <div class="d">Company Introduction</div>
          <div class="dec">—</div>
        </div>
        <div class="personlist">
          <el-row>
            <el-col :span="12" v-for="(i,index) in list2" :key="i.avatar">
              <div class="item">
                <div class="avatar">
                  <img :src="i.avatar" alt />
                </div>
                <div :class="{position:true,awidth:index==6}">
                  <img src="../assets/img/btn.png" alt />
                  <div class="txt">{{i.position}}</div>
                </div>
                <div class="nickname">{{i.nickname}}</div>
                <div class="description">{{i.des}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="bottom">
        <div class="text">一键启动业务之旅</div>
        <div class="contact">联系我们</div>
      </div>
    </div>
</div>
    <!-- <h1>{{ $t('title') }}</h1>
    <div>
      <el-button @click="switchLanguage('en')" type="primary">英文</el-button>
      <el-button @click="switchLanguage('zh')" type="primary">中文</el-button>
    </div>-->

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  data() {
    return {
      list: [
        { id: 1, path: require("../assets/img/list/i1.png") },
        { id: 2, path: require("../assets/img/list/i2.png") },
        { id: 3, path: require("../assets/img/list/i3.png") },
        { id: 4, path: require("../assets/img/list/i6.png") },
        { id: 5, path: require("../assets/img/list/i5.png") },
        { id: 6, path: require("../assets/img/list/i4.png") },
        { id: 7, path: require("../assets/img/list/i8.png") },
        { id: 8, path: require("../assets/img/list/i7.png") }
      ],
      list2: [
        {
          position: "首席执行官",
          nickname: "亚历山大·奥切切克",
          avatar: require("../assets/img/list2/f1.png"),
          des:
            "亚历山大·奥切切克的经历包括在道富环球顾问公司(SSGA)的15年职业生涯中担任高级董事总经理和投资委员会主席。他负责监督。20个国家的业务，负责450多项投资策略和2.1万亿美元的投资管理客户资产，包括管理SSGA的所有私募股权业务。曾担任多个SSGA实体的董事会成员，包括TheTuckerman Group(一家房地产投资管理公司)和Global Alliance(一家全球精品资产管理公司的控股公司)在SSGA期间，他还在2008-10年间担任金融服务部门协调委员会(FSSCC)的志愿者主席。"
        },
        {
          position: "首席财务官",
          nickname: "克里斯·惠勒",
          avatar: require("../assets/img/list2/f2.png"),
          des:
            "毕业于哈佛大学商学院，获工商管理硕士学位。克里斯·惠勒在技术行业拥有二十多年的战略和运营财务领导经验。在管理和扩展财务运营以促进增长和盈利，以及公私资金募集和收购方面拥有丰富的经验。在公司任职期间，带领公司成功完成了首次公开募股(IP0)，并在IP0后领导财务部门长达四年。曾在Carbonite、Constant Contact、NuoDB和Salesnet等多家软件公司担任高级财务主管职务曾担任在线保险市场EverQuote的首席财务官长达九年。"
        },
        {
          position: "全球房地产主管",
          nickname: "瓦伦丁·伊舍尔",
          avatar: require("../assets/img/list2/f3.png"),
          des:
            "瓦伦丁·伊舍尔于2020年8月被任命为全球房地产主管。截至2021年6月30日，瓦伦丁。伊舍尔负责代表机构和散户投资者管理超过230亿美元的商业房地产监督我们的综合基金、投资、开发和物业管理平台以及战略性全球合作伙伴关系的管理。于2019年2月担任首席运营官，并于2019年8月担任独立账户董事总经理的扩大职权范围。拥有15年的房地产基金管理经验，并代表批发投资者参与了多个大型开发项目的交付。"
        },
        {
          position: "全球债务咨询主管",
          nickname: "梁俊",
          avatar: require("../assets/img/list2/f4.png"),
          des:
            "作为公司的债务咨询主管，梁俊领导着一支敬业的专业团队，负责所有主要的债务咨询交易，是40多家关系银行、债券投资者、金融律师、交易顾问和评级机构的主要接口。梁俊的交易经验涵盖基础设施、能源和公用事业、房地产、老年护理和私募股权领域，并曾在多个本地和国际债务和资本市场筹集资金。在建立债务咨询团队之前，在EnterpriseGroup接受了ArthurAndersen的特许会计师培训提供风险保证、财务建模、破产和管理咨询服务"
        },
        {
          position: "转型与投资总监",
          nickname: "维克多·卢",
          avatar: require("../assets/img/list2/f5.png"),
          des:
            "维克多·卢是一位非常成功且具有变革性的执行领导者。他的行政领导经验涵盖金融服务的退休金、投资管理和人寿保险领域，包括投资运营、产品、人员和文化、金融、战略和转型职能。他在金融服务行业拥有超过15年的经验。在加入公司之前，维克多·卢是Sunsuper以及NAB集团的企业和机构财富业务的高管。在这两个组织中，他都是执行团队的一员，将这些企业转变为高绩效的行业领导者在公司,维克多·卢负责全球财富管理的投资业务和转型。维克多·卢拥有经济学学士学位，并且是澳大利亚精算师协会的会员。"
        },
        {
          position: "首席运营官",
          nickname: "郭慧娴",
          avatar: require("../assets/img/list2/f6.png"),
          des:
            "目前担任公司运营官，她领导区域的战略发展、规划。协调公司在全球的各项业务。郭慧娴在公司超过7年，2016年她被任命为亚太合规主管，2017年她担任亚太首席行政官。郭慧娴拥有曼彻斯特大学的会计和计算机科学理学学士学位和伦敦政治经济学院的管理学理学硕士学位"
        },
        {
          position: "增值基础设施股权全球联席主管",
          nickname: "蒋京全",
          avatar: require("../assets/img/list2/f7.png"),
          des:
            "蒋京全是公司在全球的基础设施股权业务负责人以及新加坡办事处的首席执行官曾与Ruben Bhagobati一起担任新的欧洲、美洲和亚洲基础设施股权领导委员会的联合主席。蒋京全于2015年加入公司,拥有丰富的经验。领导了公司多项关键交易，特别是在交通和能源领域，包括对ESVAGT、黑德兰港机场、利兹布拉德福德机场、伦敦卢顿机场、亚太地区、Sterlite Power和CLH的关键投资"
        },
        {
          position: "董事会顾问",
          nickname: "艾哈迈德·班纳博士阁下",
          avatar: require("../assets/img/list2/f8.png"),
          des:
            "艾哈迈德·班纳博士阁下通过跨行业的无边界合作，为董事会和管理层解决关键敏感的领导力问题。艾哈迈德·班纳博士阁下在法律、公司治理、企业管理、组织咨询、数字化转型和组织心理学方面拥有第一手经验。艾哈迈德·班纳博士阁下曾担任米其林亚太区商务总监，为大客户开发新的产品和服务解决方案。在此之前，他曾在罗兰贝格战略咨询公司担任项目经理，为能源和化工行业的跨国公司提供服务。艾哈迈德·班纳博士阁下 拥有华东理工大学材料科学工程学士学位、英语学士学位和法国枫丹白露欧洲工商管理学院 (INSEAD)工商管理硕士学位。"
        }
      ]
    };
  },
  methods: {
    switchLanguage(language) {
      this.$i18n.locale = language;
      localStorage.setItem("language", language);
    }
  }
};
</script>

<style lang="scss" scoped>
.top-wapper {
  width: 100%;
  height: 1020px;

  .inner {
    width: 1300px;
    position: relative;
    height: 100%;
    background-color: #bfa;
    margin: 0 auto;
    .titletext {
      font-family: eryaxindahei;
      font-size: 78px;
      color: #ffffff;
      position: absolute;
      top: 276px;
      line-height: 94px;
      text-align: left;
      font-style: normal;
    }

    .type {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      position: absolute;
      top: 391px;
      font-size: 18px;
      color: #cac4bf;
      line-height: 25px;
      text-align: left;
    }
    .solution {
      position: absolute;
      top: 554px;
      border-radius: 22px;
      border: 1px solid #ffffff;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      padding: 10px 51px;
      font-size: 18px;
      color: #ffffff;
      line-height: 25px;
      text-align: left;
    }
    }
  }
.content-wapper {
  width: 100%;
  height: 4322px;
  background: url("../assets/img/bg.png");
  position: relative;
  background-size: cover;
  .inner {
    position: relative;
    height: 4322px;
    margin: 0 auto;
    width: 1300px;
    .title {
      position: absolute;
      top: 117px;
      color: #fff;
      .img {
        width: 66px;
        height: 56px;
        background-color: yellow;
        float: left;
      }
      .t {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 40px;
        color: #ffffff;
        line-height: 56px;
        text-align: left;
        float: left;
      }
      .d {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 28px;
        text-align: left;
      }
    }
    .t2 {
      top: 2014px;
    }
    .teamlist {
      position: absolute;
      top: 351px;
      .el-col {
        margin-bottom: 147px;
      }
      // display: flex;
      .item {
        width: 340px;
        height: 384px;
        border: 1px dashed #fff;
        img {
          width: 340px;
          height: 384px;
        }
      }
    }
    .personlist {
      position: absolute;
      top: 2176px;
      .el-col {
        margin-bottom: 142px;
      }
      .avatar {
        width: 176px;
        height: 176px;
        border: 1px dashed #fff;
        // background-color: #bfa;
        float: left;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .position {
        width: 284px;
        // padding: 0 50px;
        height: 110px;
        margin-left: 17px;
        // background-color: #fff;
        position: relative;
        float: left;
        img {
          width: 100%;
          height: 110px;
        }
        .txt {
          position: absolute;
          top: 20px;
          width: 100%;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #2c1d06;
          line-height: 33px;
          text-align: center;
        }
      }
      .awidth {
        width: 420px;
        // height: 120px;
        img {
          height: 120px;
        }
      }
      .nickname {
        width: 278px;
        height: 33px;
        // background-color: skyblue;
        float: left;
        margin-left: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        line-height: 33px;
        // text-align: center;
        font-style: normal;
      }
      .description {
        width: 620px;
        height: 145px;
        float: left;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        line-height: 29px;
        text-align: left;
        margin-left: 15px;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 256px;
    background-color: #ff8a23;
    position: relative;
    .text {
      position: absolute;
      top: 71px;
      left: 240px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 32px;
      color: #ffffff;
      line-height: 45px;
      text-align: center;
    }
    .contact{
      width: 186px;
      height: 48px;
      background-color: #222326;
      border-radius: 24px;
      position: absolute;
      left: 240px;
      top: 146px;
      line-height: 48px;
      text-align: center;
      color: #fff;
    }
  }
}
</style>