import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'companyIntroduction',
    component: () => import("@/views/CompanyIntroduction.vue"),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: '/alent',
    name: 'alent',
    component: () => import("@/views/alentTeam.vue"),
  }
  ,
  {
    path: '/business',
    name: 'business',
    component: () => import("@/views/business.vue"),
  }
  ,
  {
    path: '/header',
    name: 'header',
    component: () => import("@/components/Header.vue"),
  },
  {
    path: '/footer',
    name: 'footer',
    component: () => import("@/components/Footer.vue"),
  },
  {
    path: '/menu',
    name: 'mnu',
    component: () => import("@/views/phonemenu.vue"),
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import("@/views/Partner.vue"),
  },
  // {
  //   path: '/companyIntroduction',
  //   name: 'companyIntroduction',
  //   component: () => import("@/views/CompanyIntroduction.vue"),
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
