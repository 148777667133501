<template>
    <div class=''>
            <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
      <router-link to="/alent">人才</router-link>
    </nav>
    </div>
</template>

<script>
export default {
    data () {
        return {
            
        };
    },
    components: {
        
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>

<style lang='scss' scoped>

</style>