import request from "./request";

// 获取轮播图数据
export const getbanner = () => {
    return request({
        url:"/setting.Carousel/list?ApiToken=&page=1&limit=10",
        method:'get'
    })
}

// 获取多语言数据
export const getfirstlang = () => {
    return request({
        url:"/menhu/contactService/info",
        method:'get'
    })
}
// 获取公司列表
export const getcompanylist = () => {
    return request({
        url:"/menhu/honor/getAllList",
        method:'get'
    })
}
// 获取领导列表
export const getleaderlist = () => {
    return request({
        url:"/menhu/leadeRship/getAllList",
        method:'get'
    })
}
// 获取人物列表
export const getImgs = () => {
    return request({
        url:"/menhu/productType/getAllList",
        method:'get'
    })
}

// 获取英文列表
export const getContent = () => {
    return request({
        url:"/content.Content/info?content_id=1",
        method:'get'
    })
}

// 获取中文列表
export const getContent_zh = () => {
    return request({
        url:"/content.Content/info?content_id=2",
        method:'get'
    })
}
// 