// src/i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import { getfirstlang } from "@/api/index.js";
Vue.use(VueI18n);

const messages = {
  en: {
    title1: "Company Introduction",
    title2: "Business Overview",
    title3: "Team Introduction",
    title4: "Company Outlook",
    title5: "cooperative partner",
    title5: "Social Media Sharing",
    title5: "Social Media Sharing",
    title6: "Android client download",
    title7: "Apple client download",
    title8: "Video Account",
    title9: "Business Consultation",
    title10: "Partner Consultation",
    title11: "Business Cooperation",
    title12: "Contact customer service",

    alentText1: "Core Talent Team",
    alentText2: "From small retail investors to large institutions",
    alentText3: "Solutions",
    alentText4: "Excellent Team Resources",
    alentText5: "Personal Advantages",
    alentText6: "One-click Start Business Growth Journey",
    alentText7: "Contact Us",

    alentu1t1: "CEO",
    alentu1t2: "Alexander Ocheczech",
    alentu1t3:
      "Alexander Ocheczech's career includes 15 years at State Street Global Advisors (SSGA) as Senior Managing Director and Chairman of the Investment Committee. He oversaw operations in 20 countries, managed over 450 investment strategies, and $2.1 trillion in client assets, including all of SSGA's private equity business. He served on the boards of several SSGA entities, including The Tuckerman Group (a real estate investment management company) and Global Alliance (a holding company for global boutique asset management firms). During his time at SSGA, he also volunteered as Chairman of the Financial Services Sector Coordinating Council (FSSCC) from 2008-10.",

    alentu2t1: "CFO",
    alentu2t2: "Chris Wheeler",
    alentu2t3:
      "Chris Wheeler holds an MBA from Harvard Business School. He has over 20 years of strategic and operational financial leadership experience in the technology sector. He has extensive experience in managing and scaling financial operations to drive growth and profitability, as well as raising public and private funds and acquisitions. During his tenure, he led the company through a successful IPO and headed the finance department for four years post-IPO. He has held senior financial roles at multiple software companies including Carbonite, Constant Contact, NuoDB, and Salesnet, and served as CFO of the online insurance marketplace EverQuote for nine years.",

    alentu3t1: "Global Head of Real Estate",
    alentu3t2: "Valentin Ischer",
    alentu3t3:
      "Valentin Ischer was appointed as the Global Head of Real Estate in August 2020. As of June 30, 2021, Valentin Ischer manages over $23 billion in commercial real estate on behalf of institutional and retail investors. He oversees our integrated fund, investment, development, and property management platform, as well as strategic global partnerships. He served as COO in February 2019 and expanded his role to Managing Director of Separate Accounts in August 2019. He has 15 years of real estate fund management experience and has delivered several large-scale development projects on behalf of wholesale investors.",

    alentu4t1: "Global Head of Debt Advisory",
    alentu4t2: "Liang Jun",
    alentu4t3:
      "As the company's Head of Debt Advisory, Liang Jun leads a dedicated team responsible for all major debt advisory transactions, serving as the main interface for over 40 relationship banks, bond investors, financial lawyers, deal advisors, and rating agencies. Liang Jun's transaction experience spans infrastructure, energy and utilities, real estate, aged care, and private equity, having raised funds in various local and international debt and capital markets. Before establishing the debt advisory team, he trained as a Chartered Accountant at Arthur Andersen in the Enterprise Group, providing risk assurance, financial modeling, insolvency, and management consulting services.",

    alentu5t1: "Director of Transformation and Investment",
    alentu5t2: "Victor Lu",
    alentu5t3:
      "Victor Lu is a highly successful and transformational executive leader. His administrative leadership experience spans pension funds, investment management, and life insurance sectors in financial services, including investment operations, products, people and culture, finance, strategy, and transformation functions. He has over 15 years of experience in the financial services industry. Before joining the company, Victor Lu was an executive at Sunsuper and NAB Group's Corporate and Institutional Wealth business. In both organizations, he was part of the executive team that transformed these businesses into high-performing industry leaders. At the company, Victor Lu is responsible for the global wealth management investment business and transformation. He holds a Bachelor's degree in Economics and is a member of the Institute of Actuaries of Australia.",

    alentu6t1: "COO",
    alentu6t2: "Guo Huixian",
    alentu6t3:
      "Currently serving as the company's Chief Operating Officer, Guo Huixian leads regional strategic development, planning, and coordination of the company's global operations. Guo Huixian has been with the company for over seven years. In 2016, she was appointed as Head of Compliance for the Asia-Pacific region, and in 2017, she became Chief Administrative Officer for the Asia-Pacific. Guo Huixian holds a Bachelor's degree in Accounting and Computer Science from the University of Manchester and a Master's degree in Management from the London School of Economics and Political Science.",

    alentu7t1: "Co-Head of Global Value-Added Infrastructure Equity",
    alentu7t2: "Jiang Jingquan",
    alentu7t3:
      "Jiang Jingquan is the global head of the company's infrastructure equity business and the CEO of the Singapore office. He served as co-chair of the new European, American, and Asian Infrastructure Equity Leadership Committee with Ruben Bhagobati. Jiang Jingquan joined the company in 2015 and has extensive experience. He has led several key transactions, particularly in the transportation and energy sectors, including critical investments in ESVAGT, Hedland Port Airport, Leeds Bradford Airport, London Luton Airport, Sterlite Power in the Asia-Pacific region, and CLH.",

    alentu8t1: "Board Advisor",
    alentu8t2: "Dr. Ahmed Banner",
    alentu8t3:
      "Dr. Ahmed Banner provides cross-industry boundaryless cooperation to solve critical and sensitive leadership issues for the board and management. Dr. Ahmed Banner has firsthand experience in law, corporate governance, corporate management, organizational consulting, digital transformation, and organizational psychology. Dr. Ahmed Banner served as the Commercial Director for Michelin Asia Pacific, developing new product and service solutions for major clients. Before that, he was a project manager at Roland Berger Strategy Consultants, serving multinational companies in the energy and chemical industries. Dr. Ahmed Banner holds a Bachelor's degree in Materials Science Engineering and English from East China University of Science and Technology, and an MBA from INSEAD in Fontainebleau, France.",

    abouttext1: "Focus on entering the global medical field",
    abouttext2:
      "Committed to promoting globalization and innovation in the healthcare industry, providing efficient and reliable solutions to address global health challenges",
    abouttext3: "Solutions",
    abouttext4:
      "We bring unique insights covering energy, power, transportation, utilities, airports, seaports, communication infrastructure, social infrastructure, aged care, and more. The combined expertise of over 100 global infrastructure investment specialists also enables us to cover all aspects of the capital structure, providing more future investment options for our clients.",
    abouttext5: "Leading Medical Innovation",
    abouttext6: "Power Generation Enhancement",
    abouttext7: "Internet",
    abouttext8: "Disruptive Technology Accelerates Energy Transition",
    abouttext9:
      "Through our agile and flexible approach, we offer comprehensive asset and wealth management solutions, enabling clients to access exclusive opportunities typically reserved for large institutions. With a solid foundation in Asia and a global presence, we are at the forefront of capturing emerging market opportunities. We have an increasing number of offices on three continents and a diverse and professional team. We understand the importance of trust and hope our clients see us as a safe home for their business and capital. We grow with our clients and work towards the same goals.",

    abouttext1: "Focused on advancing in the global medical field",
    abouttext2:
      "Committed to promoting globalization and innovation in the healthcare industry to provide efficient and reliable solutions for global health challenges",
    abouttext3: "Solutions",
    abouttext4:
      "We bring unique insights covering energy, power, transportation, utilities, airports, seaports, communication infrastructure, social infrastructure, elderly care, etc. The combined expertise of over 100 global infrastructure investment experts also enables us to cover all aspects of capital structure to provide our clients with more future investment options",
    abouttext5: "Leading medical innovation",
    abouttext6: "Countries",
    abouttext7: "Hospitals",
    abouttext8: "Disruptive technologies accelerating the energy transition",
    abouttext9:
      "Through our agile and flexible approach, we provide comprehensive asset and wealth management solutions, allowing clients to access exclusive opportunities typically reserved for large institutions. With a solid foundation in Asia and a global presence, we are at the forefront of seizing emerging market opportunities, with an increasing number of offices across three continents and a diverse and professional team. We understand the importance of trust and hope our clients see us as a safe home for their business and capital. We grow with our clients, working towards the same goals",

    abouti1: "Advanced technical support",
    abouti2: "60% conversion rate",
    abouti3:
      "Providing precise, real-time diagnostic and treatment recommendations for medical teams",
    abouti4: "Secure health data management",
    abouti5: "Innovative wind power generation equipment",
    abouti6:
      "Ensuring patients receive the best treatment outcomes and quality of life",
    abouti7: "Professional technical team",
    abouti8:
      "Committed to continuous improvement and expansion through innovation and collaboration",
    abouti9: "Our service range",

    aboutf1t1: "Secure health data management",
    aboutf1t2:
      "Using blockchain encryption technology to securely store and share patient health records, protecting privacy information from being tampered with or stolen",
    aboutf2t1: "Cross-border collaboration platform",
    aboutf2t2:
      "Establishing a data exchange platform across medical institutions to promote real-time information sharing among doctors, researchers, and insurance companies, accelerating the diagnosis and treatment decision-making process",
    aboutf3t1: "Application of smart contracts",
    aboutf3t2:
      "Automating the payment and claims process of medical services through smart contracts, reducing operating costs and improving efficiency, ensuring fairness and transparency in all aspects",

    // Company Introduction
    companyIntroduction_text1: "Youhai Finance Building Dreams Forward",
    companyIntroduction_text2:
      "IPO, Equity Financing, Debt Financing, Private Equity Banking, SPAC Services, PIPE, ELOCs, Debt Advisory, Bridge Financing",
    companyIntroduction_text3: "Solutions",
    companyIntroduction_text4: "Company Introduction",
    companyIntroduction_text5: "Company Introduction",
    companyIntroduction_text6:
      "Founded in 2015, we are a full-service financial institution deeply rooted in Asia with a global presence. We have a long history and strength in real estate and infrastructure, and extensive experience in fixed income, equities, and multi-asset solutions.",
    companyIntroduction_text7: "Global Locations",
    companyIntroduction_text8: "Company Services",
    companyIntroduction_text9: "Equity Financing",
    companyIntroduction_text10: "Debt Financing",
    companyIntroduction_text11: "Private Equity Banking",
    companyIntroduction_text12: "SPAC Services",
    companyIntroduction_text13: "PIPE",
    companyIntroduction_text14: "ELOCs",
    companyIntroduction_text15: "Debt Advisory",
    companyIntroduction_text16: "Bridge Financing",
    companyIntroduction_text17: "Business Core",
    companyIntroduction_text18:
      "The company's growth is steadily stabilizing, and the industry is returning to rationality. The company connects Asia with Europe and the Americas, with offices in Hong Kong, mainland China, Indonesia, Malaysia, Vietnam, India, Sweden, and the UAE, providing support in multiple regions. We operate in all major asset classes, with a particular focus on real estate and infrastructure. We are committed to delivering superior investment results to our clients, which is at the core of everything we do. By identifying our clients' goals, we create investment solutions to help them achieve those goals.",
    companyIntroduction_text19: "Operating Principles",
    companyIntroduction_text20:
      "Customers are the driving force of all company actions. The company's work is to meet customer expectations and provide high-quality products, establishing long-term relationships based on mutual understanding with customers. Respect individuality, whether employees or customers, allowing everyone to develop fully. Work together and form team spirit. In the eyes of customers, there should be only one Youhai company. Employees and managers must be honest and upright. We hold our rights and status in the international market primarily through reformative research on regional markets worldwide: through the efforts of over 2,000 sales and trade professionals, we study products that cater to customer tastes, provide analysis to meet customer needs, and leverage international forces in distribution and trade.",
    companyIntroduction_text21: "Salesperson",
    companyIntroduction_text22: "Trade",
    companyIntroduction_text23: "Business Core",
    companyIntroduction_text24: "Asset Side",
    companyIntroduction_text25: "Third-party Assets",
    companyIntroduction_text26: "Self-service Products",
    companyIntroduction_text27: "Consult Immediately",
    companyIntroduction_text28: "Domestic Institutional Services",
    companyIntroduction_text29: "U.S. Institutional Services",
    companyIntroduction_text30: "Partners",
    companyIntroduction_text31: "Domestic Partners",
    companyIntroduction_text331: "Foreign partners",
    companyIntroduction_text32: "YOU HAI",
    companyIntroduction_text33: "Mainland China",
    companyIntroduction_text34: "Hong Kong",
    companyIntroduction_text35: "Indonesia",
    companyIntroduction_text36: "Malaysia",
    companyIntroduction_text37: "Vietnam",
    companyIntroduction_text38: "India",
    companyIntroduction_text39: "Sweden",
    companyIntroduction_text40: "UAE",
    companyIntroduction_text41: "Training/Seminars",
    companyIntroduction_text42: "Document Process Coordination",
    companyIntroduction_text43: "Market Due Diligence",
    companyIntroduction_text44: "Asset Management Consulting",
    companyIntroduction_text45: "Business license",

    // Company Business
    business_text1: "Youhai Company Business",
    business_text2: "From small retail investors to large institutions",
    business_text3: "Infrastructure Investment Management",
    business_text4: "Real Estate Investment Development Management",
    business_text5: "Multi-asset Management",
    business_text6: "Infrastructure Investment Management",
    business_text7: "Company Introduction",
    business_text8:
      "We bring unique insights covering energy, power, transportation, utilities, airports, seaports, communication infrastructure, social infrastructure, aged care, and more. The combined expertise of over 100 global infrastructure investment specialists also enables us to cover all aspects of the capital structure, providing more future investment options for our clients.",
    business_text9: "Investment Experts",
    business_text10: "Power",
    business_text11: "Transportation",
    business_text12: "Utilities",
    business_text13: "Airports",
    business_text14: "Communication Infrastructure",
    business_text15: "Seaports",
    business_text16: "Social Infrastructure",
    business_text17: "Aged Care",
    business_text18:
      "Our clients range from small retail investors to large institutions. This means we have a deep understanding of infrastructure as an asset class from both an asset management and investor perspective. Our goal is to provide stable, long-term returns, inflation protection, and immunity from volatility for all clients.",
    business_text19: "Real Estate Investment Development Management",
    business_text20: "Project",
    business_text21:
      "We achieve this goal by creating exceptional real estate experiences, aiming to deliver sustainable returns to our clients, supporting their success, and adding value to the lives of millions of people and communities who shop, work, or experience the places we manage.",
    business_text22:
      "We are one of the largest and most experienced direct real estate fund management companies in the Asia-Pacific region. We digitize and visualize the investment process management, enhancing clients' investment process control capabilities, and providing data support for investment land acquisition decisions, making real estate operation management smarter.",
    business_text23: "Medical and Elderly Financial Services",
    business_text24:
      "We are committed to the comprehensive financial path, exploring the medical and elderly care ecosystem, and continuously implementing digital reforms. Since 2015, each strategic upgrade has stemmed from listening to and understanding customer needs.",
    business_text25:
      "We have found that the public's demand for financial services is concentrated on obtaining a 'worry-free, time-saving, and cost-effective' financial consumption experience. Based on this, the company continuously launches 'innovative products and services with industry reform, customer perception, and market impact,' among which elderly financial services are undoubtedly the most needed, most concerned, and most anticipated key area by the public. It is also the important focus of our practice of 'finance for the people.' Therefore, the company leverages the strategic advantage of 'comprehensive finance + medical and elderly care,' actively serving the three pillars of elderly care, building four platforms for investment, insurance, medical care, and elderly care, and supporting the elderly financial ecosystem with leading medical technology.",
    business_text26: "Comprehensive Finance",
    business_text27: "Medical and Elderly Care",
    business_text28: "Building Investment",
    business_text29: "Building Insurance",
    business_text30: "Building Medical Care",
    business_text31: "Building Elderly Care",
    business_text32: "Multi-asset Investment Management",
    business_text33:
      "We are one of the largest and most experienced direct real estate fund management companies in the Asia-Pacific region. We digitize and visualize the investment process management, enhancing clients' investment process control capabilities, and providing data support for investment land acquisition decisions, making real estate operation management smarter.",
    business_text34:
      "Combining localized market demand and wealth management client characteristics, we have constructed a company research framework based on four pillars: 'investment research platform, major asset allocation platform, asset capability circle, and product quality control system,' focusing on how to enhance the stable holding experience of products. The goal is to effectively manage portfolio drawdowns through diversified investments, creating absolute return stable products and active management capabilities.",
    business_text35: "Solutions",
    business_text36:
      "Investing in multi-asset solutions can provide diversification and flexibility that a single asset class investment might not be able to offer.",
    business_text37: "Talent",
    business_text38:
      "We have a team of 36 investment professionals with an average of 17 years of experience, specializing in multi-asset portfolio management. They, in turn, leverage the advice of over 250 global asset class investment professionals.",
    business_text39: "Philosophy",
    business_text40:
      "We believe that asset allocation is the primary driver of investment returns. · We believe that diversification can reduce risk, thereby increasing the long-term return potential of funds. · We believe that successfully managing diversified portfolios requires skill.",
    business_text41: "Insights",
    business_text42:
      "We focus on using a wide range of investment strategies and tools, as well as insights we can obtain from internal and external fund managers, setting us apart. Thought leadership and insights generate the most value when shared, giving our clients more confidence to achieve their investment goals.",

    //合作伙伴
    Partner_text1: "Partners",
    Partner_text2:
      "Strategic partnership with ATEC Spine to drive innovation and development in the field of spinal health",
    Partner_text3: "Solutions",
    Partner_text4: "Strategic partnership with ATEC",
    Partner_text5: "Company Introduction",
    Partner_text6: "ATEC spine",
    Partner_text7:
      "We are pleased to announce a strategic partnership with the world's leading spine surgery technology company",
    Partner_text8: "ATEC Spine",
    Partner_text9:
      "This partnership aims to combine our expertise and resources in financial investment with ATEC Spine's excellent spinal surgery products and technologies to drive innovation and development in the field of spinal health",
    Partner_text10:
      "We look forward to providing more advanced and effective treatment solutions to medical professionals through this partnership to improve patient quality of life and treatment outcomes. Together with ATEC Spine, we will explore new market opportunities and expand our influence and market share globally through shared resources and expertise",
    Partner_text11: "About ATEC Spine",
    Partner_text12:
      "ATEC Spine is a leading medical technology company focused on designing, developing, and marketing innovative spinal surgery products and solutions. Our mission is to improve patient quality of life and treatment outcomes through technological innovation and clinical excellence",
    Partner_text13: "Products and services include but are not limited to",
    Partner_text14: "Spinal implants and devices",
    Partner_text15:
      "Including spinal screws, disc replacement systems, orthopedic implants, etc., used to support and stabilize spinal structures",
    Partner_text16: "Surgical instruments and equipment",
    Partner_text17:
      "High-quality surgical instruments and equipment provided to spine surgeons to help achieve precise surgical operations",
    Partner_text18: "Education and training",
    Partner_text19:
      "Continuing education and technical training provided to medical professionals to ensure they fully understand and apply our technologies and products",

    new_title_1: "Our Mission",
    new_title_2: "Our innovative solutions",
    new_title_3: "Future challenge",

    new_content_1:
      "Improve the efficiency and quality of medical services through scientific innovation and patient care, so that every patient has access to the best treatment they need",

    new_t_1: "Data management and privacy protection",
    new_t_2: "Cost control and accessibility",
    new_t_3: "Technology is backward and standardized",
    new_t_4: "Talent shortage and burden on healthcare providers",
    new_t_5: "An aging society and an increase in chronic diseases",
    new_t_6: "Global health crisis and outbreak response",

    new_c_1:
      "The management and security of healthcare data are key challenges. The diversity, confidentiality and security of data require the introduction of high-level technical solutions such as blockchain and encryption",
    new_c_2:
      "The cost of health care is rising, affecting access and equity. To ensure that more people have access to necessary health care, effective cost containment strategies and more efficient service delivery need to be found",
    new_c_3:
      "There are incompatibilities in technical standards and information exchange between different healthcare institutions and countries, which affects the sharing of data and the collaboration of healthcare information systems",
    new_c_4:
      "The healthcare industry faces a talent shortage, especially in specific fields such as nursing and high-tech medical technology. In addition, healthcare providers face workload and stress, which can affect their work efficiency and service quality",
    new_c_5:
      "As the global population ages, the burden of chronic diseases is increasing. This puts greater demands on the health care system and requires better management and long-term care programs",
    new_c_6:
      "Sudden infectious diseases and global health crises demonstrate the vulnerability of healthcare systems in responding to crises. Stronger emergency response capabilities and collaborative mechanisms are needed to address future health challenges",
  },
  zh: {
    title1: "公司介绍",
    title2: "公司业务",
    title3: "团队介绍",
    title4: "公司展望",
    title5: "合作伙伴",

    title5: "社交分享",
    title6: "安卓客户端下载",
    title7: "苹果客户端下载",
    title8: "视频号",
    title9: "业务咨询",
    title10: "合伙人咨询",
    title11: "商务合作",
    title12: "联系客服",

    alentText1: "核心人才队伍",
    alentText2: "从小型散户投资者到大型机构",
    alentText3: "解决方案",
    alentText4: "优秀的团队资源",
    alentText5: "个人优势",
    alentText6: "一键启动业务增长之旅",
    alentText7: "联系我们",

    alentu1t1: "首席执行官",
    alentu1t2: "亚历山大·奥切切克",
    alentu1t3:
      "亚历山大·奥切切克的经历包括在道富环球顾问公司(SSGA)的15年职业生涯中担任高级董事总经理和投资委员会主席。他负责监督。20个国家的业务，负责450多项投资策略和2.1万亿美元的投资管理客户资产，包括管理SSGA的所有私募股权业务。曾担任多个SSGA实体的董事会成员，包括TheTuckerman Group(一家房地产投资管理公司)和Global Alliance(一家全球精品资产管理公司的控股公司)在SSGA期间，他还在2008-10年间担任金融服务部门协调委员会(FSSCC)的志愿者主席。",
    alentu2t1: "首席财务官",
    alentu2t2: "克里斯·惠勒",
    alentu2t3:
      "毕业于哈佛大学商学院，获工商管理硕士学位。克里斯·惠勒在技术行业拥有二十多年的战略和运营财务领导经验。在管理和扩展财务运营以促进增长和盈利，以及公私资金募集和收购方面拥有丰富的经验。在公司任职期间，带领公司成功完成了首次公开募股(IP0)，并在IP0后领导财务部门长达四年。曾在Carbonite、Constant Contact、NuoDB和Salesnet等多家软件公司担任高级财务主管职务曾担任在线保险市场EverQuote的首席财务官长达九年。",
    alentu3t1: "全球房地产主管",
    alentu3t2: "瓦伦丁·伊舍尔",
    alentu3t3:
      "瓦伦丁·伊舍尔于2020年8月被任命为全球房地产主管。截至2021年6月30日，瓦伦丁。伊舍尔负责代表机构和散户投资者管理超过230亿美元的商业房地产监督我们的综合基金、投资、开发和物业管理平台以及战略性全球合作伙伴关系的管理。于2019年2月担任首席运营官，并于2019年8月担任独立账户董事总经理的扩大职权范围。拥有15年的房地产基金管理经验，并代表批发投资者参与了多个大型开发项目的交付。",
    alentu4t1: "全球债务咨询主管",
    alentu4t2: "梁俊",
    alentu4t3:
      "作为公司的债务咨询主管，梁俊领导着一支敬业的专业团队，负责所有主要的债务咨询交易，是40多家关系银行、债券投资者、金融律师、交易顾问和评级机构的主要接口。梁俊的交易经验涵盖基础设施、能源和公用事业、房地产、老年护理和私募股权领域，并曾在多个本地和国际债务和资本市场筹集资金。在建立债务咨询团队之前，在EnterpriseGroup接受了ArthurAndersen的特许会计师培训提供风险保证、财务建模、破产和管理咨询服务",
    alentu5t1: "转型与投资总监",
    alentu5t2: "维克多·卢",
    alentu5t3:
      "维克多·卢是一位非常成功且具有变革性的执行领导者。他的行政领导经验涵盖金融服务的退休金、投资管理和人寿保险领域，包括投资运营、产品、人员和文化、金融、战略和转型职能。他在金融服务行业拥有超过15年的经验。在加入公司之前，维克多·卢是Sunsuper以及NAB集团的企业和机构财富业务的高管。在这两个组织中，他都是执行团队的一员，将这些企业转变为高绩效的行业领导者在公司,维克多·卢负责全球财富管理的投资业务和转型。维克多·卢拥有经济学学士学位，并且是澳大利亚精算师协会的会员。",
    alentu6t1: "首席运营官",
    alentu6t2: "郭慧娴",
    alentu6t3:
      "目前担任公司运营官，她领导区域的战略发展、规划。协调公司在全球的各项业务。郭慧娴在公司超过7年，2016年她被任命为亚太合规主管，2017年她担任亚太首席行政官。郭慧娴拥有曼彻斯特大学的会计和计算机科学理学学士学位和伦敦政治经济学院的管理学理学硕士学位",
    alentu7t1: "增值基础设施股权全球联席主管",
    alentu7t2: "蒋京全",
    alentu7t3:
      "蒋京全是公司在全球的基础设施股权业务负责人以及新加坡办事处的首席执行官曾与Ruben Bhagobati一起担任新的欧洲、美洲和亚洲基础设施股权领导委员会的联合主席。蒋京全于2015年加入公司,拥有丰富的经验。领导了公司多项关键交易，特别是在交通和能源领域，包括对ESVAGT、黑德兰港机场、利兹布拉德福德机场、伦敦卢顿机场、亚太地区、Sterlite Power和CLH的关键投资",
    alentu8t1: "董事会顾问",
    alentu8t2: "艾哈迈德·班纳博士阁下",
    alentu8t3:
      "艾哈迈德·班纳博士阁下通过跨行业的无边界合作，为董事会和管理层解决关键敏感的领导力问题。艾哈迈德·班纳博士阁下在法律、公司治理、企业管理、组织咨询、数字化转型和组织心理学方面拥有第一手经验。艾哈迈德·班纳博士阁下曾担任米其林亚太区商务总监，为大客户开发新的产品和服务解决方案。在此之前，他曾在罗兰贝格战略咨询公司担任项目经理，为能源和化工行业的跨国公司提供服务。艾哈迈德·班纳博士阁下 拥有华东理工大学材料科学工程学士学位、英语学士学位和法国枫丹白露欧洲工商管理学院 (INSEAD)工商管理硕士学位。",

    abouttext1: "重点进军全球医疗领域",
    abouttext2:
      "致力于推动医疗行业的全球化和创新，以提供高效、可靠的解决方案来应对全球范围内的健康挑战",
    abouttext3: "解决方案",
    abouttext4:
      "我们带来了独特的洞察力，涵盖能源、电力、运输、公用事业、机场、海港、通信基础设施、社会基础设施、老年护理等。全球 100多名基础设施投资专家的综合专业知识也使我们能够涵盖资本结构的各个方面为我们的客户提供更多未来投资选择",
    abouttext5: "引领医疗创新",
    abouttext6: "国家",
    abouttext7: "医院",
    abouttext8: "颠覆性技术加速能源转型",
    abouttext9:
      "通过我们敏捷灵活的方法，我们提供全面的资产和财富管理解决方案，使客户能够获得通常为大型机构保留的独家机会。凭借在亚洲的坚实基础和全球影响力，我们在抓住新兴市场机遇方面处于领先地位，在三大洲设有越来越多的办事处，拥有多元化而专业的专业团队。我们了解信任的重要性，并希望我们的客户将我们视为其业务和资本的安全家园。我们与客户共同成长，朝着相同的目标努力",

    abouti1: "先进的技术支持",
    abouti2: "转化率60%",
    abouti3: "为医疗团队提供精确、实时的诊断和治疗建议",
    abouti4: "安全的健康数据管理",
    abouti5: "创新风力发电设备",
    abouti6: "确保患者获得最佳的治疗结果和生活质量",
    abouti7: "专业的技术团队",
    abouti8: "致力于通过创新和协作，不断改善和扩展",
    abouti9: "我们的服务范围",

    aboutf1t1: "安全的健康数据管理",
    aboutf1t2:
      "利用区块链的加密技术，确保患者的健康记录安全存储和共享，保护隐私信息不被篡改或盗用",
    aboutf2t1: "跨界协作的平台",
    aboutf2t2:
      "建立跨医疗机构的数据交换平台，促进医生、研究人员和保险公司之间的实时信息共享，加速诊断和治疗决策的过程",
    aboutf3t1: "智能合约的应用",
    aboutf3t2:
      "通过智能合约自动化处理医疗服务的支付和理赔流程，降低操作成本并提升效率，确保各方面的公平和透明",
    //公司介绍
    companyIntroduction_text1: "优海金融 筑梦前行",
    companyIntroduction_text2:
      "首次公开招股、股权融资、债务融资、私募股权银行、SPAC服务、PIPE、ELOCs、债务咨询、过桥融资",
    companyIntroduction_text3: "解决方案",
    companyIntroduction_text4: "企业简介",
    companyIntroduction_text5: "Company Introduction",
    companyIntroduction_text6:
      "成立于2015年，是一家深深扎根亚洲、业务遍及全球的全方位服务金融机构。在房地产和基础设施方面拥有悠久的历史和实力，并在固定收益、股票和多资产解决方案方面拥有丰富的经验",
    companyIntroduction_text7: "全球地点",
    companyIntroduction_text8: "公司服务",
    companyIntroduction_text9: "股权融资",
    companyIntroduction_text10: "债务融资",
    companyIntroduction_text11: "私募股权银行",
    companyIntroduction_text12: "SPAC服务",
    companyIntroduction_text13: "PIPE",
    companyIntroduction_text14: "ELOCs",
    companyIntroduction_text15: "债务咨询",
    companyIntroduction_text16: "过桥融资",
    companyIntroduction_text17: "业务核心",
    companyIntroduction_text18:
      "目前公司规模增长日趋稳定，行业发展也在回归理性。公司连接亚洲与欧洲和美洲，在香港、中国大陆、印度尼西亚、马来西亚、越南、印度、瑞典和阿联酋设有办事处，可以在多个地区为您提供支持。我们在所有主要资产类别中开展业务，特别关注房地产和基础设施。我们致力于为客户提供卓越的投资成果一直是我们所做一切的核心。通过找出我们的客户想要实现的目标，然后构建投资解决方案来帮助他们实现目标",
    companyIntroduction_text19: "经营原则",
    companyIntroduction_text20:
      "顾客是公司一切行动的推动力，公司的工作就是要实现顾客的期望并提供高质量的产品，要与顾客建立起相互理解的长期关系。要尊重个性，无论是雇员还是顾客，要让每个人充分的发展。要协同工作，要形成团队精神，在顾客眼中要只有一个优海公司。还有无论是雇员还是经理都要正直，诚实。我们在国际市场中拥有的权利与所处的姿态主要是对世界区域时常的改革性研究：通过2000多个销售与贸易的专业人员的努力，研究能迎合客户口味的产品，提供能满足客户需求的分析研究，以及分配与贸易中的国际力量",
    companyIntroduction_text21: "销售员",
    companyIntroduction_text22: "贸易",
    companyIntroduction_text23: "业务核心",
    companyIntroduction_text24: "资产端",
    companyIntroduction_text25: "第三方资产",
    companyIntroduction_text26: "自助产品",
    companyIntroduction_text27: "立即咨询",
    companyIntroduction_text28: "中国境内机构服务",
    companyIntroduction_text29: "美国境内机构服务",
    companyIntroduction_text30: "合作伙伴",
    companyIntroduction_text31: "国内合作伙伴",
    companyIntroduction_text331: "国外合作伙伴",
    companyIntroduction_text32: "YOU HAI",
    companyIntroduction_text33: "中国大陆",
    companyIntroduction_text34: "香港",
    companyIntroduction_text35: "印度尼西亚",
    companyIntroduction_text36: "马来西亚",
    companyIntroduction_text37: "越南",
    companyIntroduction_text38: "印度",
    companyIntroduction_text39: "瑞典",
    companyIntroduction_text40: "阿联酋",
    companyIntroduction_text41: "培训/研讨会",
    companyIntroduction_text42: "文件流程配合",
    companyIntroduction_text43: "市场落地尽调",
    companyIntroduction_text44: "资管运作咨询",
    companyIntroduction_text45: "营业执照",
    //公司业务
    business_text1: "优海 公司业务",
    business_text2: "从小型散户投资者到大型机构",
    business_text3: "基础设施投资管理",
    business_text4: "房地产投资开发管理",
    business_text5: "多元资产管理",
    business_text6: "基础设施投资管理",
    business_text7: "Company Introduction",
    business_text8:
      "我们带来了独特的洞察力，涵盖能源、电力、运输、公用事业、机场、海港、通信基础设施、社会基础设施、老年护理等。全球 100多名基础设施投资专家的综合专业知识也使我们能够涵盖资本结构的各个方面为我们的客户提供更多未来投资选择",
    business_text9: "投资专家",
    business_text10: "电力",
    business_text11: "运输",
    business_text12: "公用事业",
    business_text13: "机场",
    business_text14: "通信基础设施",
    business_text15: "海港",
    business_text16: "社会基础设施",
    business_text17: "老年护理",
    business_text18:
      "我们的客户范围从小型散户投资者到大型机构。这意味着我们从资产管理和投资者的角度对基础设施作为一种资产类别有着深刻的理解。我们的目标是为所有客户提供稳定、长期的回报、通胀保护和免受波动影响",
    business_text19: "房地产投资开发管理",
    business_text20: "Project",
    business_text21:
      "我们通过创造卓越的房地产体验来实现这一目标，旨在为我们的客户提供可持续的回报，支持我们的客户取得成功，并为数百万购物、工作或体验我们管理的场所的人和社区的生活增加价值",
    business_text22:
      "我们是亚太地区最大的直接房地产基金管理公司之一，也是最有经验的公司之一，让投资过程管理数字化、可视化，提升客户投资过程管控能力，为投资拿地决策提供数据支撑。让不动产经营管理更智慧",
    business_text23: "医疗养老金融",
    business_text24:
      "坚定综合金融道路，探索医疗养老生态，持续数字化改革，从2015年开始每一次战略升级都来源于对客户需求的聆听与洞察",
    business_text25:
      "我们发现老百姓对于金融服务的诉求集中——获得“省心、省时、又省钱”的金融消费体验。基于此，公司持续推出“行业有革新、客户有感知、市场有影响”的尖刀产品与服务，而其中养老金融无疑是民生最需要、民众最关注、市场最期待的关键领域，也是我们自身践行“金融为民”的重要落点。因此，公司发挥“综合金融+医疗养老”战略优势，积极服务养老三大支柱，打造投资、保险、医疗、养老四大平台，并以领先的医疗科技为支撑，构筑养老金融大生态",
    business_text26: "综合金融",
    business_text27: "医疗养老",
    business_text28: "打造投资",
    business_text29: "打造保险",
    business_text30: "打造医疗",
    business_text31: "打造养老",
    business_text32: "多元资产投资管理",
    business_text33:
      "我们是亚太地区最大的直接房地产基金管理公司之一，也是最有经验的公司之一，让投资过程管理数字化、可视化，提升客户投资过程管控能力，为投资拿地决策提供数据支撑。让不动产经营管理更智慧",
    business_text34:
      "结合本土化市场需求和理财客户特征，我们围绕着如何提升产品稳健持有体验方面，构建了以“投资研究平台、大类资产配置平台、资产能力圈和产品质量管控体系”四大支柱为基础的公司投研框架体系，目的是通过分散化投资，有效管理组合回撤，打造绝对收益稳健产品和主动管理能力",
    business_text35: "解决方案",
    business_text36:
      "在对一种资产类别的投资可能无法提供多样化或灵活性的情况下投资于多资产解决方案可以通过在多个资产类别",
    business_text37: "人才",
    business_text38:
      "我们拥有一支由36名 平均拥有17年经验的 投资专业人士组成的团队，他们专门从事 多资产投资组合管理。他们反过来利用全球250多名专业资产类别投资专业人士的建议",
    business_text39: "哲学",
    business_text40:
      "我们相信资产配置是投资回报的主要驱动力。 ·我们相信多元化可以降低风险，从而提高基金的长期回报潜力。 ·我们认为成功管理多元化的投资组合需要技巧",
    business_text41: "见解",
    business_text42:
      "我们专注使用广泛的投资策略和工具，以及我们可以从内部和外部基金经理那里获得的洞察力网络，使我们与众不同思想领导力和洞察力在分享时产生最大价值让我们客户更有信心实现他们的投资目标",

    //合作伙伴
    Partner_text1: "合作伙伴",
    Partner_text2:
      "ATEC Spine达成战略合作协议，共同推动脊柱健康领域的创新和发展",
    Partner_text3: "解决方案",
    Partner_text4: "与ATEC公司达成战略合作",
    Partner_text5: "Company Introduction",
    Partner_text6: "ATEC spine",
    Partner_text7: "我们很高兴宣布与全球领先的脊柱外科技术公司",
    Partner_text8: "ATEC Spine",
    Partner_text9:
      "达成战略合作协议。这一合作旨在结合我们在金融投资方面的专业知识和资源，与ATEC Spine卓越的脊柱外科产品和技术结合，共同推动脊柱健康领域的创新和发展",
    Partner_text10:
      "我们期待通过这一合作，为医疗专业人员提供更先进、更有效的治疗解决方案，以改善患者的生活质量和治疗成果。我们和ATEC Spine将共同探索新的市场机会，并通过共享资源和专业知识，扩展在全球市场上的影响力和市场份额",
    Partner_text11: "关于ATEC Spine 公司",
    Partner_text12:
      "ATEC Spine是一家领先的医疗技术公司，专注于设计、开发和销售创新的脊柱外科产品和解决方案。我们的使命是通过技术创新和临床卓越，改善患者的生活质量和治疗结果",
    Partner_text13: "产品和服务包括但不限于",
    Partner_text14: "脊柱植入物和装置",
    Partner_text15:
      "包括脊柱螺钉、椎间盘置换系统、骨科植入物等 用于支持和稳定脊柱结构",
    Partner_text16: "手术器械和设备",
    Partner_text17:
      "提供给脊柱外科医生使用的高质量手术器械和设备帮助实现精确的手术操作",
    Partner_text18: "教育和培训",
    Partner_text19:
      "为医疗专业人员提供的持续教育和技术培训，确保他们能够充分掌握和应用我们的技术和产品",

    //
    new_title_1: "我们的使命",
    new_title_2: "我们的创新的解决方案",
    new_title_3: "未来挑战",

    new_content_1:
      "通过科学创新和患者关怀，提升医疗服务的效率和质量，让每一位患者都能够获得他们所需的最佳治疗",

    new_t_1: "数据管理和隐私保护",
    new_t_2: "成本控制和可及性",
    new_t_3: "技术落后和标准化",
    new_t_4: "人才短缺和医疗服务提供者的负担",
    new_t_5: "老龄化社会和慢性病的增加",
    new_t_6: "全球卫生危机和疫情应对",

    new_c_1:
      "医疗数据的管理和安全性是关键挑战。数据的多样性、保密性和安全性要求引入高级别的技术解决方案，如区块链和加密技术",
    new_c_2:
      "医疗服务的成本不断上升，影响了医疗的可及性和公平性。为了确保更多人能够获得必要的医疗保健，需要找到有效的成本控制策略和提供更有效率的服务",
    new_c_3:
      "不同医疗机构和国家之间存在技术标准和信息交换的不兼容性，这影响了数据共享和医疗信息系统的协作性",
    new_c_4:
      "医疗行业面临人才短缺，特别是在特定领域如护理和高科技医疗技术方面。此外，医疗服务提供者面临工作负担和压力，这可能影响他们的工作效率和服务质量",
    new_c_5:
      "随着全球人口的老龄化，慢性病的负担也在增加。这对医疗系统提出了更高的要求需要更好的管理和长期护理方案",
    new_c_6:
      "突发性传染病和全球健康危机展示了医 疗系统在应对危机时的脆弱性。需要建 立更强大的应急响应能力和协作机制来 应对未来的健康挑战",
  },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "zh",
  messages,
});

async function fetchData() {
  try {
    const res = await getfirstlang();
    console.log("Fetched data:", res.data.data);
    let langs = res.data.data;
    // messages.en.abouttext1 = langs.abouttext1_en;
    // messages.zh.abouttext1 = langs.abouttext1;
    for (let key in langs) {
      if (key.endsWith("_en")) {
        let zhKey = key.slice(0, -3); // Remove '_en' to find the corresponding Chinese key
        messages.en[zhKey] = langs[key];
      } else {
        messages.zh[key] = langs[key];
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

fetchData();

export default i18n;
